<template>
	<div class="login">
				<div class="logo_box">
					<img class="logo_img" src="@/assets/image/kangcerLogo.png" alt="">
				</div>

				<div class="login_box">
					<p class="login_title">医院客户关系管理系统</p>
					<p class="login_text">账号登陆</p>
					<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
						class="demo-ruleForm">
						<el-form-item prop="UserName" class="myUserName">
							<p style="position: absolute;z-index: 999;top: -20%;width: 20px;height: 20px;">
								<img src="../../assets/image/user.png" alt="" style="width: 20px;height: 20px;">
							</p>
							<el-input @keyup.enter="submitForm('ruleForm')" type="text" placeholder="请输入账号" v-model="ruleForm.UserName" autocomplete="off">
							</el-input>
						</el-form-item>
						<el-form-item prop="Password" class="myPassword">
							<p style="position: absolute;z-index: 999;top: -20%;width: 20px;height: 20px;">
								<img src="../../assets/image/pwd.png" alt="" style="width: 20px;height: 20px;">
							</p>
							<el-input @keyup.enter="submitForm('ruleForm')" type="password" placeholder="请输入密码" v-model="ruleForm.Password"
								autocomplete="off"></el-input>
						</el-form-item>
						<el-form-item class="mymemoryAccount">
							<el-checkbox v-model="memoryAccount" style="color: #333333;font-size: 12px;">记住账号</el-checkbox>
						</el-form-item>
						<el-form-item style="text-align: center;">
							<el-button style="background-color: #2476E9;  height: 46px;width: 340px;" type="primary" @click="submitForm('ruleForm')"
								:loading="isLoading">登录</el-button>
						</el-form-item>
					</el-form>
				</div>

		<p class="bottom_copyright">上海康策软件有限公司版权所有 服务热线：021-60713139</p>
	</div>
</template>
<script>
	export default {
		data() {
			var validateUserName = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入账号'));
				} else {
					if (this.ruleForm.UserName !== '') {
						this.$refs.ruleForm.validateField('UserName');
					}
					callback();
				}
			};
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'));
				} else {
					if (this.ruleForm.Password !== '') {
						this.$refs.ruleForm.validateField('Password');
					}
					callback();
				}
			};
			return {
				isLoading: false,
				memoryAccount: false,
				ruleForm: {
					UserName: '',
					Password: '',
				},
				rules: {
					Password: [{
						validator: validatePass,
						trigger: 'blur'
					}],
					UserName: [{
						validator: validateUserName,
						trigger: 'blur'
					}]
				}
			};

		},
		
		computed: {

		},
		
		mounted() {
			if(localStorage.getItem('account')){
				this.ruleForm.UserName = localStorage.getItem('account')
				this.memoryAccount = true
				// localStorage.getItem(key)
			}else{
				this.ruleForm.UserName = ''
				this.memoryAccount = false
			}
			
			// if(localStorage.getItem('memoryAccount')){
			// 	this.memoryAccount = true
			// }
			
			
			
		},



		methods: {


			submitForm(formName) {
				this.isLoading = true;
				this.$refs[formName].validate((valid) => {
					if (valid) {
						var data = {
							UserName: this.ruleForm.UserName,
							Password: this.ruleForm.Password
						}
						this.$post("/account/login", data).then(
							res => {
								console.log(res, 3);
								if (res.SysConfig.VoiceWay == 3) {
									// 接入第三方语音网关
									sessionStorage.setItem("m7pw", data.Password);
								}
								if (this.memoryAccount) {
									localStorage.setItem("account", data.UserName);
									localStorage.setItem("memoryAccount", this.memoryAccount);
								} else {
									localStorage.removeItem("account");
								}
								window.location.href = "/Entrances/index";
								var loginDate = Date.parse(new Date());
								localStorage.setItem("loginDate", loginDate);
							}, err => {
								this.isLoading = false;
								console.log(err, 2)
								// alert(message)
								this.$message.error(err.message)
							}).catch(err => {
							console.log(err, 1)
							this.isLoading = false;
						})
					} else {
						console.log('error submit!!');
						this.isLoading = false;
						return false;
					}
				});


			},
		}
	}
</script>
<style lang="scss" >
	body {}
	.el-input__inner{
		border-top:0;
		border-right:0;
		border-left:0;
		border-bottom: 1px solid #DEE0E4 ;
		outline: none;
		border-radius: 0;
		padding-left: 30px;
	}
	input::-webkit-input-placeholder{
	    
	    font-size:14px;
		// padding-left: 20px;
	}
	input::-moz-placeholder{   /* Mozilla Firefox 19+ */
		font-size:14px;
		padding-left: 20px;
	}
	input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
	    padding-left: 20px;
		font-size:14px;
	}
	input:-ms-input-placeholder{  /* Internet Explorer 10-11 */
	    padding-left: 20px;
		font-size:14px;
	}
	.myUserName{
		margin-bottom: 40px;
		position: relative;
		
	}
	.myPassword{
		margin-bottom: 32px;
		position: relative;
	}
	.mymemoryAccount{
		margin-bottom: 42px;
	}

	.login {
		background-image: url('../../assets/image/bg.png');
		// background-size:cover;
		// background-repeat:no-repeat;
		background-size: 100% 100%;
		height: 100%;

		.el-row {
			margin-right: 0px !important;
		}

		.logo_box {
			box-sizing: border-box;
			// padding: 50px 0px 40px;
			padding-top: 70px;
			padding-left: 100px;
			.logo_img {
				width: 136.68px;
				height: 40px;
			}
		}

		.login_img {
			width: 100%;
		}

		.login_content {
			display: flex;
			align-items: center;
		}

		.login_box {
			// margin-left: 60%;
			// margin-top: 10%;
			// margin: 0 auto;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			margin: auto;
			height: 500px;
			width: 420px;
			background: #fff;
			padding: 10px 20px;
			border-radius: 4px;
			// line-height:500px ;

			.el-form-item__content {
				margin-left: 0px !important;
			}

			.el-button--primary {
				width: 100%;
			}

			.login_title {
				text-align: center;
				color: #333333;
				font-size: 22px;
				font-weight: 600;
			}
			.login_text{
				font-size: 18px;
				color: #000000;
				margin-top: 39px;
				border-bottom: 2px solid #2476E9 ;
				width: 72px;
				box-sizing: border-box;
				padding-bottom: 12px;
				margin-bottom: 38px;
			}
		}

		.bottom_copyright {
			margin: 0px;
			// padding-bottom: 20px;
			font-size: 12px;
			color: #fff;
			text-align: center;
			position: fixed;
			bottom: 3%;
			width: 100%;
			// height: ;
		}
	}
</style>
