// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/image/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".el-input__inner{border-top:0;border-right:0;border-left:0;border-bottom:1px solid #dee0e4;outline:none;border-radius:0;padding-left:30px}input::-webkit-input-placeholder{font-size:14px}input:-moz-placeholder,input::-moz-placeholder{font-size:14px;padding-left:20px}input:-ms-input-placeholder{padding-left:20px;font-size:14px}.myUserName{margin-bottom:40px;position:relative}.myPassword{margin-bottom:32px;position:relative}.mymemoryAccount{margin-bottom:42px}.login{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;height:100%}.login .el-row{margin-right:0!important}.login .logo_box{box-sizing:border-box;padding-top:70px;padding-left:100px}.login .logo_box .logo_img{width:136.68px;height:40px}.login .login_img{width:100%}.login .login_content{display:flex;align-items:center}.login .login_box{position:absolute;top:0;right:0;left:0;bottom:0;margin:auto;height:500px;width:420px;background:#fff;padding:10px 20px;border-radius:4px}.login .login_box .el-form-item__content{margin-left:0!important}.login .login_box .el-button--primary{width:100%}.login .login_box .login_title{text-align:center;color:#333;font-size:22px;font-weight:600}.login .login_box .login_text{font-size:18px;color:#000;margin-top:39px;border-bottom:2px solid #2476e9;width:72px;box-sizing:border-box;padding-bottom:12px;margin-bottom:38px}.login .bottom_copyright{margin:0;font-size:12px;color:#fff;text-align:center;position:fixed;bottom:3%;width:100%}", ""]);
// Exports
module.exports = exports;
